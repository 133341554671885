import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { toast } from "react-hot-toast";
import { TwitterPicker } from "react-color";
import color from "../../../assets/color.svg";

import { useStyles } from "./styles";
import { addTag, deleteTag } from "../../../store/organizations/middleware";
import { Tag } from "../../../store/organizations/types";
import { Box } from "@material-ui/core";

type AddTagsProps = {
  orgId: string;
  tags: Array<Tag>;
};
type ColorObject = {
  hex: string;
  hsl: {
    h: number;
    s: number;
    l: number;
    a: number;
  };
  hsv: {
    h: number;
    s: number;
    v: number;
    a: number;
  };
  rgb: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  oldHue: number;
  source: string;
};
const AddTags: React.FC<AddTagsProps> = (props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [tagError, setTagError] = useState<boolean>(false);
  const [showTag, setShowTag] = useState<boolean>(false);

  const { orgId, tags } = props;
  console.log("tags", tags);

  const formik = useFormik({
    initialValues: {
      tag: "",
      color_code: "",
    },
    onSubmit: (values) => {
      if (values.tag === "") {
        setTagError(true);
        toast.error("Tag cannot be empty.", {
          duration: 1000,
        });
        return;
      }

      dispatch(addTag(Number(orgId), values.tag, values.color_code));
      formik.setFieldValue("tag", "");
      formik.setFieldValue("color_code", "");
    },
  });

  const updateColor = (color: ColorObject) => {
    const hexWithoutHash = color.hex.slice(1);
    formik.setFieldValue("color_code", hexWithoutHash);
  };

  const removeTag = (tag: Tag) => dispatch(deleteTag(Number(orgId), tag));
  return (
    <Paper className={styles.tagForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Tag Management</Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.tagListContainer}>
            {tags === null || tags.length === 0 ? (
              <div className={styles.noTagsMsg}>No Tags Found</div>
            ) : (
              tags?.map((tag, idx) => (
                <Chip
                  key={`tag-${idx}`}
                  className={styles.tag}
                  label={<Typography variant="body1"> {tag?.name}</Typography>}
                  onDelete={() => removeTag(tag)}
                  component="div" // Or specify the appropriate component type (e.g., "span", "button", etc.)
                  style={{
                    backgroundColor: `#${tag.color}`,
                  }}
                />
              ))
            )}
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            className={styles.tagInput}
            id="tags"
            error={tagError}
            name="tag"
            size="small"
            variant="outlined"
            placeholder="Enter tag name"
            fullWidth={true}
            value={formik.values.tag}
            onChange={formik.handleChange}
            onClick={() => {
              if (tagError) {
                setTagError(false);
              }
            }}
          />
          {showTag ? (
            <Grid item xs={12} sm={6} className={styles.colorBox}>
              <TwitterPicker triangle="top-right" onChange={updateColor} />
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={1} sm={1}>
          <Box
            className={styles.customBox}
            onClick={() => {
              setShowTag(!showTag);
            }}
          >
            <img
              src={color}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5} sm={5}>
          <Button
            fullWidth={true}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableFocusRipple={true}
            disableRipple={true}
          >
            Add New Tag
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddTags;
