import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { useStyles as styles } from "./styles";
import { TableFooter } from "@material-ui/core";
import { Typography } from "@mui/material";

type Driver = {
  count: number;
  driver_id: number;
  driver_name: string;
};

type DriversTableProps = {
  drivers: Driver[];
};

const headCells: Array<HeaderCell> = [
  { id: "name", leftAlign: false, disablePadding: false, label: "Name" },

  {
    id: "totalDeliveries",
    leftAlign: false,
    disablePadding: false,
    label: "Total Delieveries (Today)",
  },
];

const DriversTable: React.FC<DriversTableProps> = (props) => {
  const classes = styles();
  const nav = useNavigate();

  const rowsPerPage = 10;
  const [page] = useState(0);
  const { drivers } = props;
  return (
    <Paper className={classes.paper}>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="drivers table"
        >
          <TableHeader headerCells={headCells} />
          <TableBody>
            {Object.entries(drivers).map((value, idx) => {
              let driver = value[1];
              return (
                <TableRow
                  className={classes.row}
                  tabIndex={-1}
                  key={`enhanced-table-checkbox-${idx}`}
                  onClick={() => {
                    nav(`/drivers/${driver.driver_id}`);
                  }}
                  style={{
                    backgroundColor: idx % 2 === 0 ? "#F6F8FCCC" : "#FFFFFF", // Alternate colors
                  }}
                >
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                    width={"75%"}
                  >
                    <Typography variant="body1">
                      {driver.driver_name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    <Typography variant="body1" align="center">
                      {driver.count}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pages}
              rowsPerPageOptions={[]}
              count={Object.entries(drivers).length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                count === 0 ? null : `${count} drivers`
              }
              onPageChange={() => {}}
            />
          </TableRow>
        </TableFooter>
      </Table> */}
    </Paper>
  );
};

export default DriversTable;
