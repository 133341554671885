import { get, post, put, del } from "./api";

export const getAdminStats = () => {
  const url = "/api/v1/admin/stats";
  return get(url);
};

export const getOrderStats = (org_id, delivery_date) => {
  const url = `/api/v1/organizations/${org_id}/stats?delivery_date=${delivery_date}`;
  return get(url);
};

export const addNewOrganization = (org: any) => {
  const url = "/api/v1/organizations";
  return post(url, org);
};

export const getOrganizations = () => {
  const url = "/api/v1/organizations";
  return get(url);
};

export const updateOrder = (org_id: any, order_id: any, payload: any) => {
  const url = `/api/v1/organizations/${org_id}/orders/${order_id}`;
  return put(url, payload);
};

export const addNewUser = (user: any) => {
  const url = "/api/v1/users";
  return post(url, user);
};

export const deleteOrganization = (org_id: number) => {
  const url = `/api/v1/organizations/${Number(org_id)}`;
  return del(url);
};

export const deleteUser = (user_id: number) => {
  const url = `/api/v1/users/delete`;
  return put(url, { user_id });
};

export const assignPharmacyToServiceProvider = (values) => {
  const url = `/api/v1/organizations/serviceproviders/${values.service_provider_id}/pharmacies`;
  return put(url, values);
};

export const getOrganizationStats = (org_id: number) => {
  const url = `/api/v1/organizations/${Number(org_id)}/stats`;
  return get(url);
};

export const assignUser = (payload) => {
  const url = `/api/v1/users/assign`;
  return post(url, payload);
};

export const getTimeLine = (payload) => {
  const url = `/api/v1/organizations/${payload.org_id}/orders/${payload.order_id}/timeline`;
  return get(url);
};

export const getOrganizationDrivers = (org_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers`;
  return get(url);
};

export const assignDriverApi = (org_id, payload) => {
  const url = `/api/v1/organizations/${org_id}/drivers`;
  return post(url, payload);
};

export const fetchPatientsByName = (org_id, name) => {
  const url = `/api/v1/organizations/${org_id}/patients/search?name=${name}`;
  return get(url);
};

export const updateOrganisation = (org_id, payload) => {
  const url = `/api/v1/organizations/${org_id}`;
  return put(url, payload);
};

export const addStaffMember = (org_id, payload) => {
  const url = `/api/v1/organizations/${org_id}/users`;
  return post(url, payload);
};

export const fetchDriversByOrg = (org_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers/table`;
  return get(url);
};

export const fetchTagsByOrders = (org_id, order_id) => {
  const url = `/api/v1/organizations/${org_id}/orders/${order_id}/tags`;
  return get(url);
};

export const addOrderTags = (org_id, order_id, payload) => {
  const url = `/api/v1/organizations/${org_id}/orders/${order_id}/tags`;
  return post(url, payload);
};

export const getUploadedImages = async (org_id, order_id) => {
  return get(`/api/v1/organizations/${org_id}/orders/${order_id}/assets`);
};

export const addPharmacyNewUser = async (pharmacy_id, payload) => {
  return post(`/api/v1/organizations/${pharmacy_id}/users`, payload);
};
export const getPharmacyNewUsers = async (pharmacy_id) => {
  return get(`/api/v1/organizations/${pharmacy_id}/users`);
};

export const deletePharmacy = async (pharmacy_id) => {
  return del(`/api/v1/organizations/${pharmacy_id}`);
};
export const deletePharmacyUser = async (pharmacy_id, user_id) => {
  return put(
    `/api/v1/organizations/${pharmacy_id}/users/${user_id}/remove`,
    {}
  );
};

export const fetchPatientDetail = (org_id, pharmacy_id) => {
  const url = `/api/v1/organizations/${org_id}/pharmacies/${pharmacy_id}/details`;
  return get(url);
};

export const fetchDriversDetailsById = (org_id, driver_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers/${driver_id}`;
  return get(url);
};

export const fetchDriverStats = (org_id, driver_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers/${driver_id}/stats`;
  return get(url);
};

export const getActiveRoute = (org_id, driver_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers/${driver_id}/routes/active`;
  return get(url);
};

export const getActiveRouteDetails = (org_id, driver_id, route_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers/${driver_id}/routes/${route_id}`;
  return get(url);
};

export const fetchDriverPharmacies = (org_id, driver_id) => {
  const url = `/api/v1/organizations/${org_id}/drivers/${driver_id}/pharmacies/assignments`;
  return get(url);
};

export const fetchServiceAgreement = (org_id, pharmacy_id) => {
  const url = `/api/v1/organizations/${org_id}/pharmacies/${pharmacy_id}/service-agreements`;
  return get(url);
};

export const fetchServiceAgreementZones = (org_id, pharmacy_id) => {
  const url = `/api/v1/organizations/${org_id}/pharmacies/${pharmacy_id}/service-agreements/zones`;
  return get(url);
};

export const addZone = (org_id, pharmacy_id, payload) => {
  const url = `/api/v1/organizations/${org_id}/pharmacies/${pharmacy_id}/service-agreements/zones`;
  return post(url, payload);
};

export const deleteZone = (org_id, pharmacy_id, zone_id) => {
  const url = `/api/v1/organizations/${org_id}/pharmacies/${pharmacy_id}/service-agreements/zones/${zone_id}`;
  return del(url);
};

export const updateDeliveryType = (org_id, pharmacy_id, payload) => {
  const url = `/api/v1/organizations/${org_id}/pharmacies/${pharmacy_id}/service-agreements`;
  return put(url, payload);
};

export const removeOrderTag = (org_id, order_id, tag_id) => {
  const url = `/api/v1/organizations/${org_id}/orders/${order_id}/tags/${parseInt(
    tag_id
  )}/delete`;
  return put(url, null);
};
