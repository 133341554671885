import React from "react";
import Chip from "@material-ui/core/Chip";
import { transformString } from "../../services/helper";

type StatusChipProps = {
  status: string;
};

const sanitizeStatus = (dirtyStatus: string): any => {
  const baseStyle = {
    fontSize: "0.75rem",
    fontWeight: "700",
    width: "5rem",
    height: "1.8rem",
    borderRadius: 5,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",

  };
  switch (dirtyStatus?.toLowerCase()) {
    case "ready":
      return (
        <Chip
          style={{
            backgroundColor: "#FFDF80",
            color: "#BD8F08",
            ...baseStyle,
          }}
          size="medium"
          label="Ready"
        />
      );
    case "cancelled":
      return (
        <Chip
          style={{
            backgroundColor: "#F27573",
            color: "#3C3C3C",
            ...baseStyle,
          }}
          size="small"
          label="Cancelled"
        />
      );
    case "customer_declined":
      return (
        <Chip
          style={{
            backgroundColor: "#F27573",
            color: "#3C3C3C",
            ...baseStyle,
          }}
          size="small"
          label="Declined"
        />
      );
    case "customer_absent":
      return (
        <Chip
          style={{
            backgroundColor: "#F27573",
            color: "#3C3C3C",
            ...baseStyle,
          }}
          size="small"
          label="Absent"
        />
      );
    case "in_progress":
      return (
        <Chip
          style={{
            backgroundColor: "#D0E7B7",
            color: "#579613",
            ...baseStyle,
          }}
          size="small"
          label="Absent"
        />
      );
    case "return":
      return (
        <Chip
          style={{
            backgroundColor: "#FE5A5A",
            color: "#FFFFFF",
            ...baseStyle,
          }}
          size="small"
          label="Enroute"
        />
      );
    case "delivered":
      return (
        <Chip
          style={{
            backgroundColor: "#00D084",
            color: "#FFFFFF",
            ...baseStyle,
          }}
          size="small"
          label="Delivered"
        />
      );
    case "started":
      return (
        <Chip
          style={{
            backgroundColor: "#72CFF8",
            color: "#3788AC",
            ...baseStyle,
          }}
          size="small"
          label={"Delivered"}
        />
      );
    case "routed":
      return (
        <Chip
          style={{
            backgroundColor: "#B8DDF5",
            color: "#0E72B3",
            ...baseStyle,
          }}
          size="small"
          label={"Routed"}
        />
      );
    case "with_driver":
      return (
        <Chip
          style={{
            backgroundColor: "#F3FFAE",
            color: "#7D9300",
            ...baseStyle,
          }}
          size="small"
          label={"With Driver"}
        />
      );
    case "scheduled":
      return (
        <Chip
          style={{
            backgroundColor: "#9C9AF9",
            color: "#100E56",
            ...baseStyle,
          }}
          size="small"
          label={"Scheduled"}
        />
      );
    case "tbd":
      return (
        <Chip
          style={{
            backgroundColor: "#CFCFCF",
            color: "#3C3C3C",
            ...baseStyle,
          }}
          size="small"
          label={"TBD"}
        />
      );
    default:
      return (
        <Chip
          style={{
            backgroundColor: "#ffef62",
            color: "#b28704",
            ...baseStyle,
          }}
          size="small"
          label={transformString(dirtyStatus)}
        />
      );
  }
};

const StatusChip: React.FC<StatusChipProps> = (props) => {
  const { status } = props;

  return sanitizeStatus(status);
};

export default StatusChip;
