import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useStyles as styles } from "./styles";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import ActiveRouteComponent from "../activeRouteComponent";
import { getActiveRoute, getActiveRouteDetails } from "../../services/api/requests";

const ActiveRoute: React.FC<{}> = () => {
  const location = useLocation();
  const { activeRoute, orgId, driverId } = location.state || {}; // Fallback to avoid errors

  console.log("active route123 ", activeRoute, orgId, driverId)
  const classes = styles();
  const [activeRouteDetails, setActiveRouteDetails] = useState();

  const nav = useNavigate();
  const params = useParams();

  const org = useAppSelector((state) => state.organization);

  const goDrivers = () => {
    nav(-1);
  };


  const getDriverActiveRoute = async () => {
    const activeRouteresponse = await getActiveRouteDetails(orgId, driverId, activeRoute[0]);
    console.log("active route response details", activeRouteresponse.data)

    setActiveRouteDetails(activeRouteresponse.data)
  }

  useEffect(() => {

    getDriverActiveRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Stack
        mb={2}
        display="flex"
        flexDirection="row"
        spacing={0}
        alignItems="center"
      >
        <NavigateBeforeIcon
          onClick={() => {
            goDrivers();
          }}
          className={classes.icon}
        />
        <Typography variant="h6" component="div" className={classes.heading}>
          Drivers
        </Typography>
      </Stack>

      <Typography variant="h6"> Active Route</Typography>

      <Grid
        className={classes.gridContainer}
        item
        xs={12}
        md={12}
        justifyContent="space-between"
      >
        <Grid>
          <Box mt={2}>
            <Typography variant="body1">
              Orders the driver is actively working on.
            </Typography>
          </Box>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              nav(`/drivers/${params.driverId}/activeroute/mapview`, { state: activeRouteDetails });
            }}
            className={classes.addPharmacy}
          >
            View Map
          </Button>
        </Grid>
      </Grid>

      <ActiveRouteComponent orgId={org?.org_id} orders={activeRouteDetails} />
    </Box>
  );
};

export default ActiveRoute;
