import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useStyles as styles } from "./styles";
import { useAppSelector } from "../hooks";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuPopupState from "../components/menuPopupState";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import Paper from "@material-ui/core/Paper";

import { deletePharmacy, deletePharmacyUser, fetchPatientDetail, getPharmacyNewUsers } from "../services/api/requests";
import AddUserModal from "./addUserModal";
import { formatCanadianPhoneNumber } from "../services/helper";

interface Admin {
  id: number | null;
  name: string;
  phone_number: number | null;
  ext: number;
  email: string;
}

interface Organization {
  id: number | null;
  type: string;
  name: string;
  phone_number: number | null;
  ext: number | null;
  email: string;
  unit: string;
  address: string;
  lat: number | null;
  long: number | null;
  org_tags: any;
  self_managed: boolean;
  deleted: boolean;
}

interface Admin {
  id: number | null;
  name: string;
  phone_number: number | null;
  ext: number;
  email: string;
}

interface Organization {
  id: number | null;
  type: string;
  name: string;
  phone_number: number | null;
  ext: number | null;
  email: string;
  unit: string;
  address: string;
  lat: number | null;
  long: number | null;
  org_tags: any;
  self_managed: boolean;
  deleted: boolean;
}

interface StaffMember {
  id: number;
  username: string;
  role: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: number;
}
const ChangePharmacy: React.FC<{}> = () => {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const pharmacy = location.state?.pharmacy;
  const [pharmacyUser, SetPharmacyUser] = useState<StaffMember[]>([])
  const [adminDetail, setAdminDetail] = useState<Admin>({
    id: null,
    name: "",
    phone_number: null,
    ext: 0,
    email: "",
  });
  const [organizationInfo, setOrganizationInfo] = useState<Organization>({
    id: null,
    type: "",
    name: "",
    phone_number: null,
    ext: 0,
    email: "",
    unit: "",
    address: "",
    lat: null,
    long: null,
    org_tags: null,
    self_managed: false,
    deleted: false,
  });


  console.log("change the dataa", pharmacy)
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getPharmacyUsers()
  };
  const nav = useNavigate();
  const { pharmacyId } = useParams();

  const org = useAppSelector((state) => state.organization);

  const goPharmacies = () => {
    nav("/pharmacies");
  };

  const openServiceAgreement = () => {
    nav(`/pharmacy/service-agreement/${pharmacyId}`, { state: { pharmacy } });
  };
  const getPharmacyInfo = async () => {

    try {
      let pharmacyIdInt = pharmacyId ? parseInt(pharmacyId) : pharmacyId;
      const response = await fetchPatientDetail(org.org_id, pharmacyIdInt);
      console.log("response :>> ", response);
      if (response?.data) {
        setAdminDetail(response?.data?.admin);
        setOrganizationInfo(response?.data?.organization);
      }
    } catch (error) { }
  };

  const deletPharmacy = async () => {
    let pharmacyIdInt = pharmacyId ? parseInt(pharmacyId) : pharmacyId;
    const response = await deletePharmacy(pharmacyIdInt)
    console.log("delete pharmacy", response)
    //  goPharmacies()
  }

  const deletPharmacyUser = async (id) => {
    let pharmacyIdInt = pharmacyId ? parseInt(pharmacyId) : pharmacyId;
    const response = await deletePharmacyUser(pharmacyIdInt, id)
    console.log("delete pharmacy user", response)
    await getPharmacyUsers()
  }

  const getPharmacyUsers = async () => {

    try {
      let pharmacyIdInt = pharmacyId ? parseInt(pharmacyId) : pharmacyId;
      const response = await getPharmacyNewUsers(pharmacyIdInt);
      console.log(" users response :>> ", response);
      SetPharmacyUser(response?.data?.staff)

      try {
        let pharmacyIdInt = pharmacyId ? parseInt(pharmacyId) : pharmacyId;
        const response = await fetchPatientDetail(org.org_id, pharmacyIdInt);
        console.log("response :>> ", response);
        if (response?.data) {
          setAdminDetail(response?.data?.admin);
          setOrganizationInfo(response?.data?.organization);
        }
      } catch (error) { }
    } catch (error) { }
  };

  useEffect(() => {
    getPharmacyInfo();
    getPharmacyUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Stack
        mb={2}
        display="flex"
        flexDirection="row"
        spacing={0}
        alignItems="center"
      >
        <NavigateBeforeIcon
          onClick={() => {
            goPharmacies();
          }}
          className={classes.icon}
        />
        <Typography variant="h6" component="div" className={classes.heading}>
          Pharmacies
        </Typography>
      </Stack>
      <Grid item sm={12} md={8} lg={7}>
        <Paper className={classes.card}>
          <Box className={classes.header}>
            <Typography variant="subtitle1" className={classes.subtitleClass}>
              Details
            </Typography>
            <MenuPopupState
              items={[
                {
                  name: "Modify Service Agreement",
                  handleClick: openServiceAgreement,
                },
              ]}
              onDeleteClick={() => { deletPharmacy() }}
            />
          </Box>
          <Typography variant="body1" className={classes.textStyle}>
            {organizationInfo.name}
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            {organizationInfo.address}
          </Typography>
          <Box className={classes.IconBox}>
            {/* <PhoneIphoneIcon /> */}
            <Typography variant="body1" className={classes.textStyle}>
              {organizationInfo.phone_number
                ? formatCanadianPhoneNumber(organizationInfo.phone_number)
                : "---"}
            </Typography>
          </Box>

          <Box className={classes.IconBox}>
            {/* <AlternateEmailIcon /> */}
            <Typography variant="body1" className={classes.textStyle}>
              {organizationInfo.email || "---"}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            className={classes.subtitleClass}
            style={{ marginTop: 13 }}
          >
            Admin Details
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            {adminDetail.name}
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            {adminDetail.email}
          </Typography>
          <Typography variant="body1" className={classes.textStyle}>
            {adminDetail.phone_number
              ? formatCanadianPhoneNumber(adminDetail.phone_number)
              : "---"}
          </Typography>


        </Paper>
      </Grid>
      <Grid item sm={12} md={8} lg={7} style={{ marginTop: 29 }}>
        <Paper className={classes.card}>
          <Box className={classes.header}>
            <Typography variant="subtitle1" className={classes.subtitleClass}>
              Users
            </Typography>
            <MenuPopupState onDeleteClick={() => { }} />
          </Box>
          <Typography variant="body1" className={classes.textStyle}>
            These are the following accounts that exist for employees of this
            pharmacy.
          </Typography>
          <Box style={{ marginTop: 20 }}>
            {pharmacyUser?.map((item, index) => {
              return (
                <Box className={classes.flexClass}>
                  <Typography variant="body1" className={classes.textStyle}>
                    {item.first_name} {item.last_name}
                  </Typography>
                  <Button onClick={() => { deletPharmacyUser(item.id) }} className={classes.btnStyle}>
                    <Typography variant="body1" className={classes.textStyle}>
                      Remove
                    </Typography>
                  </Button>
                </Box>
              )

            })}

          </Box>
          <Box style={{ marginTop: 79 }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                fullWidth={true}
                style={{ width: "50%" }}
                onClick={handleOpen}
                disableFocusRipple={true}
                disableRipple={true}
              >
                Add New User
              </Button>
            </Grid>
          </Box>
          <AddUserModal
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            orgId={pharmacyId}
          />
        </Paper>
      </Grid>
    </Box>
  );
};

export default ChangePharmacy;
