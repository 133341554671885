import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        chip: {
            backgroundColor: "#E0E0E0",
            color: "#222222",
            fontSize: "0.75rem",
            fontWeight: 700,
            width: "6rem",
            height: "1.8rem",
            // borderRadius: 5,
            boxShadow: theme.shadows[1],
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
                opacity: 0.8,
                boxShadow: theme.shadows[1]
            },
            '&:active': {
                transform: 'scale(0.95)'
            }
        }

    })
);
