import { path } from "ramda";
import {
  Orders,
  ADD_ORDER,
  GET_ORDERS,
  SEARCH_ORDERS,
  ORDER_DETAIL,
} from "./types";

const initialOrdersState: Orders = {
  ordersList: {},
  moreOrders: false,
  totalOrders: 0,
  orderDetails: {},
};

const orders = (state: Orders = initialOrdersState, action: any): Orders => {
  const type: any = path(["type"], action);
  const payload: any = path(["payload"], action);

  if (type) {
    switch (type) {
      case GET_ORDERS:
        return {
          ...state,
          ordersList: Object.assign({}, state.ordersList, payload.ordersList),
          moreOrders: payload.hasMore,
          totalOrders: payload.totalOrders,
        };
      case SEARCH_ORDERS:
        return {
          ...state,
          ordersList: payload.ordersList,
          moreOrders: payload.hasMore,
          totalOrders: payload.totalOrders,
        };

      case ADD_ORDER:
        return Object.assign({}, state, payload);

      case ORDER_DETAIL:
        return {
          ...state,
          orderDetails: payload,
        };
      default:
        return state;
    }
  }

  return state;
};

export default orders;
