import React, { useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Minimize } from "@mui/icons-material";
import DragAndDropList from "../dragableComponent";

interface ActiveRouteProps {
  title: string;
  isDeliveryBin?: boolean;
  data: any
  // Add other props if needed
}

const ActiveRoute: React.FC<ActiveRouteProps> = ({ title, isDeliveryBin, data }) => {
  const [isMinimized, setIsMinimized] = useState(false); // State to control minimization
  const [dropdownValue, setDropdownValue] = useState(""); // State for dropdown value

  const handleDropdownChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setDropdownValue(event.target.value as string);
  };

  return (
    <Grid item xs={12} style={{ background: "red" }}>
      <Paper
        style={{
          maxHeight: "80vh",
          overflowY: "auto",
          height: "80%",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          p={2}
          style={{
            position: "relative",
            width: isMinimized ? "100px" : "auto", // Collapse to 100px width when minimized
            transition: "width 0.3s ease", // Smooth transition
            overflow: "hidden",
          }}
        >
          {/* Chevron icon on the top right */}
          <IconButton
            onClick={() => setIsMinimized(!isMinimized)}
            style={{
              position: "absolute",
              right: 0,
              top: 0,

              transform: isMinimized ? "rotate(180deg)" : "rotate(0deg)", // Rotate the icon based on state
              transition: "transform 0.2s",
            }}
          >
            <Minimize style={{ fontSize: 20 }} />
          </IconButton>

          {/* Title and dropdown on the same line */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop={4}
            marginBottom={2}
          >
            <Typography variant="h6">{title}</Typography>

            {/* Dropdown aligned to the right with border and border-radius */}
            {!isDeliveryBin && !isMinimized && (
              <Select
                value={dropdownValue}
                onChange={handleDropdownChange}
                displayEmpty
                style={{
                  marginLeft: "auto",
                  border: "1px solid #ccc", // Border color
                  borderRadius: "5px", // Border radius
                  padding: "5px 10px", // Padding for better appearance
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="option1">Option 1</MenuItem>
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Select>
            )}
          </Box>

          {/* Collapse component to hide/show the DragAndDropList */}
          <Collapse in={!isMinimized}>
            <DragAndDropList orders={data} />
          </Collapse>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ActiveRoute;
