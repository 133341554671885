import React from "react";
import {
    Paper,
    FormControl,
    FormGroup,
    Box,
    Typography,
    Grid,
    TextField,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FormikProps } from "formik";
import { FormikCreateOrder, UpdateOrder } from "../types";

type Tag = { id: number; name: string };

type AddDeliveryTagsProps = {
    formik: FormikProps<UpdateOrder>;
    subItems: Array<Tag>;
};

const UpdateDeliveryItems: React.FC<AddDeliveryTagsProps> = (props) => {
    const { formik, subItems } = props;

    const classes = useStyles();

    return (
        <>
            <Paper elevation={4}>
                <Box className={classes.body}>

                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            <Typography
                                variant="h6"
                                component="div"
                                className={classes.heading}
                            >
                                Items
                            </Typography>
                            <Grid container spacing={2}>
                                {subItems &&
                                    subItems?.map((item, idx) => (
                                        <Grid item xs={2} key={idx}>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="subtitle1">{item.name}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        type='number'
                                                        className={classes.textField}
                                                        name={`sub_item_${item.name.toLowerCase()}`}
                                                        size="small"
                                                        onChange={formik.handleChange}
                                                        value={formik.values[`sub_item_${item.name.toLowerCase()}`]}
                                                        variant="outlined"
                                                        label=""
                                                        fullWidth={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Grid>
                        </FormGroup>
                    </FormControl>

                </Box>
            </Paper>
        </>
    );
};

export default UpdateDeliveryItems;
