import axios from "axios";

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 6000,
  withCredentials: true,
});

const get = async (path: string): Promise<any> => {
  // const response = await ax.get(path);
  // return response;

  // const response = await ax.get(path);
  // return response;

  return new Promise((resolve, reject) => {
    ax.get(path)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const post = async (
  path: string,
  payload: Object,
  onUpload?: (progresEvent: any) => void
): Promise<any> => {
  const response = await ax.post(path, payload, {
    onUploadProgress: onUpload,
  });
  return response;
};

const put = async (path: string, payload: any): Promise<any> => {
  const response = ax.put(path, payload);
  return response;
};

const del = async (path: string): Promise<any> => {
  const response = await ax.delete(path);

  return response;
};

export { ax, get, post, put, del };
