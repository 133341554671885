import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Grid } from "@material-ui/core";
import DeliveriesListing from "../Deliverieslisting";

type DriverOrderProps = {
  orders: any
};
const DragAndDropList: React.FC<DriverOrderProps> = (props) => {

  console.log("dragable componet ", props.orders)


  const [items, setItems] = useState(
    props.orders
  );



  useEffect(() => {

    setItems(props.orders)

  }, [props.orders])

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <Grid
            container
            direction="column"
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items?.map((item, index) => (
              <DeliveriesListing index={index} item={item} />
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropList;
