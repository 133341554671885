import React, { useState } from "react";
import { Typography, Box, ListItem, List } from "@material-ui/core";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { useNavigate } from "react-router";

import { FormikProps, useFormik } from "formik";
import { FormikSearchAddress } from "./types";
import useStyles from "./style";
import "./index.css";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const LogList = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [focusedInput, setFocusedInput] = useState(null);

  const isDateRangeValid = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return true; // No selection yet, so it's valid
    }
    const maxDateRange = moment(startDate).add(7, "days");
    return endDate.isSameOrBefore(maxDateRange);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    if (isDateRangeValid({ startDate, endDate })) {
      if (startDate) {
        formik.setFieldValue("startDate", new Date(startDate));
      }
      if (endDate) {
        formik.setFieldValue("endDate", new Date(endDate));
      }
    }
  };

  const formik: FormikProps<FormikSearchAddress> =
    useFormik<FormikSearchAddress>({
      initialValues: {
        deliveryAddress: "",
        startDate: new Date(),
        endDate: new Date(),
      },
      onSubmit: () => { },
    });

  const openReport = (name) => {

    navigate(`/logs/${name}`, {
      state: {
        name: `${name} Report`,
        start_date: formik.values.startDate,
        end_date: formik.values.endDate,
      },
    });
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography variant="h6">Select a report to generate</Typography>

        <Box className={classes.dateRangeContainer}>
          <DateRangePicker
            startDate={moment(formik.values.startDate)}
            startDateId="startDate"
            startDatePlaceholderText="Start"
            endDateId="endDate"
            endDatePlaceholderText="End"
            endDate={moment(formik.values.endDate)}
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            isOutsideRange={() => false}
          />
        </Box>
      </Box>
      <List className={classes.zeroPadding}>
        <ListItem
          className={classes.listItem}
          onClick={() => openReport("travel")}
          style={{
            backgroundColor: "#F6F8FCCC",
          }}
        >
          <Typography variant="body1">Travel Report</Typography>
        </ListItem>

        <ListItem
          className={classes.listItem}
          onClick={() => openReport("cash")}
        >
          <Typography variant="body1">Cash Report</Typography>
        </ListItem>
      </List>
    </Box>
  );
};

export default LogList;
