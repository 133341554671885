import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Grid from "@material-ui/core/Grid";

import { RootState } from "../store/types";
import { UserRole, ORG_ADMIN, COURIER_ADMIN } from "../services/roles";
import AddTags from "./components/addTags";
import { getOrganizationStaff } from "../services/api/settings";
import ManageStaff from "./components/manageStaff";
import OrgInfo from "./components/orgInfo";
import useStyles from "./styles";

export type OrgStaff = {
  id: string;
  username: string;
  password: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
};

type GeoLocationType = {
  lat: number;
  long: number;
};

const Settings: React.FC<{}> = () => {
  const classes = useStyles();

  const user = useSelector((state: RootState) => state.user);
  const org = useSelector((state: RootState) => state.organization);
  const [staffList, setStaffList] = useState<Array<OrgStaff>>([]);
  const [geoLocation, setGeoLocation] = useState<GeoLocationType>({
    lat: 0,
    long: 0,
  });

  useEffect(() => {
    getOrganizationStaff(org.org_id)
      .then((resp) => {
        if (resp) {
          setStaffList(resp.data.staff);
        }
        setStaffList([]);
      })
      .catch((err) => {
        setStaffList([]);
        console.error(err);
        toast.error("Failed to get staff list");
      });
  }, [org.org_id]); // Orgs can be changed if a user has access to more than one.

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((pos) => {
      setGeoLocation({ lat: pos.coords.latitude, long: pos.coords.longitude });
    });
  }, []);

  return (
    <>
      {(user.account.user_role === ORG_ADMIN ||
        user.account.user_role === COURIER_ADMIN) && (
          <>
            <Grid className={classes.container} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <OrgInfo lat={geoLocation.lat} long={geoLocation.long} />
                <br />
                {user.access?.length > 0 &&
                  user.access[0].user_role === COURIER_ADMIN ? (
                  <></>
                ) : (
                  <AddTags orgId={org.org_id} tags={org.org_tags} />
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <ManageStaff staff={staffList} />
            </Grid> */}
            </Grid>
          </>
        )}
    </>
  );
};

export default Settings;
