import React from "react";
import Chip from "@material-ui/core/Chip";
import { useStyles as style } from "./styles";


export type Tag = { id: number; name: string, color: string };
type TagChipProps = {
    tag: Tag;
};

const TagChip: React.FC<TagChipProps> = (props) => {
    const styles = style();

    const { tag } = props;
    return (


        <Chip
            key={tag.id}
            className={styles.chip}
            size="small"
            style={{
                background: '#' + tag.color,
                margin: 5, borderRadius: 5,
                height: "1.8rem",
            }}
            label={tag.name}
            clickable={true}
            variant="default"
        />
    )
};

export default TagChip;
