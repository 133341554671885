import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useDispatch } from "react-redux";

import { handleLogout } from "../../store/authentication/user/middleware";

import { useStyles } from "./styles";
import { useAppSelector } from "../../hooks";
import { COURIER_ADMIN } from "../../services/roles";
import { Typography } from "@mui/material";

import logo from "../../assets/logo3.png";

type AppMenuBarProps = {
  open: boolean;
  drawerWidth: number;
  toggleNav: any;
};

const AppMenuBar: React.FC<AppMenuBarProps> = (props) => {
  const dispatch = useDispatch();
  const { user_role } = useAppSelector((state) => state.user.account);
  const org = useAppSelector((state) => state.organization);

  const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
  const classes = useStyles(props)();
  const { open, toggleNav } = props;

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const appBarStyle = {};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        className={classes.bar}
        style={user_role === COURIER_ADMIN ? appBarStyle : {}}
        position="static"
      >
        <Toolbar>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <IconButton
              size="large"
              color="default"
              aria-label="open drawer"
              onClick={toggleNav}
              edge="start"
            >
              {window.innerWidth <= 910 ? (
                open ? (
                  <CloseIcon sx={{ color: "black", height: 26, width: 26 }} />
                ) : (
                  <MenuIcon />
                )
              ) : null}
            </IconButton>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1">Hello</Typography>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  marginRight={".1875rem"}
                >
                  {org?.org_name || "New"}
                </Typography>
                <Typography variant="body1">Team!</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} textAlign={"center"}>
            <img src={logo} alt={"logo"} />
          </Box>
          <div>
            <IconButton
              size="large"
              color="default"
              aria-label="profile"
              aria-controls="customized-menu"
              aria-haspopup="true"
              edge="end"
              onClick={handleMenuClick}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              classes={{ paper: classes.menu }}
              id="customized-menu"
              anchorEl={menuAnchor}
              getContentAnchorEl={null}
              elevation={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(menuAnchor)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => dispatch(handleLogout())}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AppMenuBar;
