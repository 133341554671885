import { createTheme } from "@material-ui/core/styles";
import { lighten, darken } from "polished";

const weelTheme = createTheme({
  palette: {
    primary: {
      light: lighten(0.1, "#3C3C3C"),
      main: "#3C3C3C",
      dark: darken(0.1, "#3C3C3C"),
    },
    secondary: {
      light: lighten(0.06, "#01c3a1"),
      main: "#01C3A1",
      dark: darken(0.06, "#01c3a1"),
    },
    error: {
      main: "#FE5A5A",
    },
  },
  typography: {
    fontFamily: '"Nunito", sans-serif !important',
    subtitle1: {
      // will always be the navigation label font.
      color: "#6a6a6a",
    },
    h6: {
      fontSize: "1.25rem !important",
      color: "#3C3C3C",
      lineHeight: "1.375rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "0.85rem !important",
      color: "#3C3C3C",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        height: "2.0625rem",
      },
    },
    MuiInputBase: {
      root: {
        height: "2.3125rem",
        color: "#3C3C3C",
      },
    },
    MuiButton: {
      root: {
        height: "30px",
        width: "150px",
        textTransform: "capitalize",
        borderRadius: 5,
        backgroundColor: "#3C3C3C",
        "&:hover": {
          backgroundColor: "#3C3C3C",
          opacity: 0.7,
        },
      },
      contained: {
        backgroundColor: "#3C3C3C",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#3C3C3C",
          opacity: 0.7,
        },
      },
      label: {
        fontSize: ".875rem",
        color: "white",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(9px, 11px) scale(1)",
      },
    },
  },
});

export default weelTheme;
