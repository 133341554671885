// libraries
import React, { useEffect, useRef, useState } from "react";
import { Paper, Box, Button, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ReactToPrint from "react-to-print";
import { useLocation } from "react-router-dom";

import { useNavigate, useParams } from "react-router";
import { useStyles } from "./style";
import LogTable from "./components/logTable";
import { cashHeadCells, cashRow, travelHeadCells } from "./constants";

import "./style.css";
import { get } from "../services/api/api";
import { useAppSelector } from "../hooks";

const LogDetails = () => {
  const location = useLocation();
  const classes = useStyles();
  const componentRef = useRef<HTMLDivElement>(null);
  const org = useAppSelector((state) => state.organization);
  const { selectedDrivers } = useAppSelector((state) => state.drivers);

  const [travelRow, setTravelRow] = useState([]);

  const nav = useNavigate();
  const params = useParams();

  const { start_date, end_date } = location.state || {}; // Destructure name and date from location.state


  // console.log("reports dates", start_date, end_date)
  // Function to get the day suffix
  const getDaySuffix = (n) => {
    if (n >= 11 && n <= 13) {
      return "th";
    }
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Format the date manually
  const formattedDate = (inputDate) => {

    console.log("input dates", inputDate)


    if (!inputDate) {
      return null;
    }
    return `${inputDate?.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    })}${getDaySuffix(inputDate.getDate())}, ${inputDate.getFullYear()}`;
  };

  const formatDate = (date) => {
    const mm = date.getMonth() + 1; // Months are zero based
    const dd = date.getDate();
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm < 10 ? "0" + mm : mm}-${dd < 10 ? "0" + dd : dd}`;
  };

  const fetchTravelReport = async () => {
    try {
      const idsConcatenated = selectedDrivers
        .map((driver) => driver.id)
        .join(",");

      const response = await get(
        `api/v1/organizations/${org.org_id}/reports/${params.logName
        }?start_date=${formatDate(new Date(start_date))}&end_date=${formatDate(
          new Date(end_date)
        )}&driver_ids=${idsConcatenated}`
      );

      setTravelRow(response.data.rows || []);
      return response.data.rows;
    } catch (error) { }
  };

  useEffect(() => {
    // if (params.logName === "travel") {
    fetchTravelReport();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerAndRow = () => {
    let header, row;
    switch (params.logName) {
      case "travel":
        header = travelHeadCells;
        row = travelRow;
        break;
      case "cash":
        header = cashHeadCells;
        row = cashRow;
        break;
      case "driver":
        header = travelHeadCells;
        row = travelRow;
        break;
      default:
        header = travelHeadCells;
        row = travelRow;
        break;
    }
    return { header, row };
  };
  return (
    <>
      <Box className={classes.root}>
        <Stack
          mb={2}
          display="flex"
          flexDirection="row"
          spacing={0}
          alignItems="center"
        >
          <NavigateBeforeIcon
            onClick={() => {
              nav("/logs");
            }}
            className={classes.icon}
          />
          <Typography variant="h6" component="div" className={classes.heading}>
            Reports
          </Typography>
        </Stack>
        <Paper className={classes.paper} elevation={4}>
          <Box className={classes.body}>
            <Stack display="flex" flexDirection="column">
              <Typography variant="h6" className={classes.title}>
                {params.logName} Report
              </Typography>
              <Typography variant="body1" className={classes.subTitle}>
                {formattedDate(start_date)} {formattedDate(end_date)}
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => (
                <Button
                  size="small"
                  variant="contained"
                  disableFocusRipple={true}
                  disableRipple={true}
                  className={classes.btnContained}
                  type="submit"
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current || null}
            />
          </Box>
        </Paper>

        <LogTable
          componentRef={componentRef}
          row={headerAndRow().row}
          headCells={headerAndRow().header}
        />
      </Box>
    </>
  );
};

export default LogDetails;
