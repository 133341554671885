import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Stack } from "@mui/material";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import { useStyles } from "./styles";

import ActiveRoute from "./component/activeRoute";
import DeliveryMapComponent from "./component/deliveryMap";

const TrakingMapContainer: React.FC<{}> = () => {
  const location = useLocation();
  console.log("location", location.state)
  const deliveryItems = location.state.delivery_bin_orders
  const styles = useStyles();
  const nav = useNavigate();
  const { deliveryId } = useParams();
  const map_api_key = process.env.REACT_APP_MAP_API_KEY || "";

  let currentOrder;
  if (deliveryId !== undefined) {
    currentOrder = location.state.order;
  }

  const goBack = () => nav(-1);
  useEffect(() => {
    const tagsBox = document.getElementById("tagsBox");
    if (tagsBox) {
      tagsBox.style.overflowY = "auto";

      (tagsBox.style as any).scrollbarWidth = "none"; // For Firefox
      (tagsBox.style as any).msOverflowStyle = "none"; // For Internet Explorer 10+
      (tagsBox.style as any).WebkitScrollbar = "none"; // For Safari and Chrome
    }
  }, []);

  return (
    <Grid container xs={12} item spacing={4} className={styles.root}>
      <Grid item sm={10} style={{ padding: 0, paddingLeft: 16 }}>
        <Stack
          mb={0}
          display="flex"
          flexDirection="row"
          spacing={0}
          padding={0}
          marginTop={2}
          alignItems="center"
        >
          <NavigateBeforeIcon
            onClick={() => goBack()}
            className={styles.icon}
          />
          <Typography variant="h6" component="div" className={styles.heading}>
            {/* {currentOrder.name} */}
          </Typography>
        </Stack>
      </Grid>

      <Grid item sm={12} md={12} lg={12}>
        <DeliveryMapComponent
          apiKey={map_api_key}
          transportMode="car"
          start={{ lat: 41.85, lng: -87.65 }}
          destinations={[
            { lat: 41.88, lng: -87.62, label: "A", address: "123 Main St" },
            { lat: 41.89, lng: -87.64, label: "B", address: "456 Elm St" },
            { lat: 41.87, lng: -87.63, label: "C", address: "789 Oak St" },
          ]}
          showOverlay={true}
        >
          <Grid>
            <ActiveRoute title="Active Route" isDeliveryBin={false} data={location.state.routed_orders
            } />
          </Grid>
          <Grid>
            <ActiveRoute title="Delivery Bin" isDeliveryBin={true} data={location.state.delivery_bin_orders} />
          </Grid>
        </DeliveryMapComponent>
      </Grid>
    </Grid>
  );
};

export default TrakingMapContainer;
