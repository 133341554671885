import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: "5px !important",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "230px",
      },
    }, chip: {
      backgroundColor: "#E0E0E0",
      color: "#222222",
      fontSize: "0.75rem",
      fontWeight: 700,
      width: "6rem",
      height: "1.8rem",
      borderRadius: 5,
      boxShadow: theme.shadows[1],
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      '&:hover': {
        opacity: 0.8,
        boxShadow: theme.shadows[1]
      },
      '&:active': {
        transform: 'scale(0.95)'
      }
    },
    title: {
      "font-weight": `${theme.typography.fontWeightBold} !important`,
      marginTop: "0px !important",
      color: theme.palette.common.black,
      lineHeight: "22px !important",
    }, heading: {
      marginTop: "0px !important",
      marginBottom: 8
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },
    body: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      overflow: "auto",
    },
    content: {
      height: "calc(160px + 60px)",
    },
    label: {
      fontSize: ".875rem",
      color: "#3C3C3C",
      lineHeight: "1.375rem",
      fontWeight: 700,
    },
    checkbox: {
      marginRight: 11,
      width: 10,
      height: 10,
    },
    radioBox: {
      display: "flex",
    },
    formGroup: {
      marginTop: 8,
    },
  })
);
