import React, { useEffect, useState } from 'react';
import { TextField } from "@material-ui/core";
import { useStyles as styles } from "./styles";

const RateInput = ({ serviceRate, onUpdateRate }) => {
    const [rate, setRate] = useState(serviceRate);
    const classes = styles();

    // Handle input change
    const handleChange = (event) => {
        setRate(event.target.value);
    };


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onUpdateRate(rate);
        }, 300);

        return () => clearTimeout(delayDebounceFn);

    }, [rate]);




    return (
        <TextField
            required={true}
            className={classes.textField}
            name="rate"
            type="number"
            size="small"
            label="Rate"
            variant="outlined"
            value={rate}
            onChange={handleChange}
        />
    );
};

export default RateInput;
