import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useStyles } from "./style";
import { Skeleton } from "@mui/material";

type StatCardProps = {
  label: string;
  stat: number | string;
  isLoading: boolean
};

const StatCard: React.FC<StatCardProps> = (props) => {
  const { label, stat, isLoading } = props;
  const classes = useStyles();
  console.log("stat", stat)
  return (
    <Card sx={{ borderRadius: "5px", width: 230 }}>
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />

        {!isLoading ? <Typography
          variant="body1"
          component="div"
          sx={{ textAlign: "center" }}
          fontWeight={700}
        >
          {stat}
        </Typography> :
          <Skeleton variant="rectangular" width={100} height={20} />
        }
      </CardContent>
    </Card>
  );
};

export default StatCard;
