import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import OrderTable from "./orderTable";
import { useStyles as styles } from "./styles";
import { getUserAccess } from "../store/authentication/access/middleware";
import { getOrganization } from "../store/organizations/middleware";
import { retrieveOrders } from "../store/orders/middleware";
import Loader from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import weelTheme from "../components/material-ui/theme";
import Divider from "@material-ui/core/Divider";

import { RootState } from "../store/types";
import StatCard from "../components/statCard";
import { getOrderStats } from "../services/api/requests";
import { Typography } from "@mui/material";
import { TextField } from "@material-ui/core";

const OrdersContainer: React.FC<{}> = () => {
  const nav = useNavigate();
  const classes = styles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.account);
  const userAccess = useSelector((state: RootState) => state.user.access);
  const orders = useSelector((state: RootState) => state.orders);
  const [isDirty, setIsDirty] = useState(false);
  // Function to format the date as mm/dd/yyyy
  const formatDate = (date) => {
    const mm = date.getMonth() + 1; // Months are zero based
    const dd = date.getDate();
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm < 10 ? "0" + mm : mm}-${dd < 10 ? "0" + dd : dd}`;
  };

  const [deliveryDate, setDeliveryDate] = useState(formatDate(new Date()));
  const [stats, setStats] = useState<any>({
    deliveriesIncomplete: 0,
    deliveriesAllTime: 0,
    patientsServiced: 0,
  });

  useEffect(() => {
    if (
      userAccess &&
      userAccess[0]?.org_id &&
      userAccess.length > 0 &&
      userAccess[0]?.org_id
    ) {
      dispatch(getOrganization(userAccess[0]?.org_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  useEffect(() => {
    if (userAccess[0]?.org_id) {
      dispatch(retrieveOrders(userAccess[0]?.org_id, 0, deliveryDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess, deliveryDate]);

  useEffect(() => {
    if (userAccess[0]?.org_id)
      getOrderStats(
        userAccess[0]?.org_id,
        deliveryDate || formatDate(new Date())
      )
        .then((resp) => {
          setStats({
            ...stats,
            deliveriesIncomplete: resp.data.unfulfilled_deliveries,
            deliveriesAllTime: resp.data.deliveries_count,
            patientsServiced: resp.data.patients_count,
          });
        })
        .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDate, userAccess]);

  useEffect(() => {
    if (!userAccess || userAccess.length === 0) {
      dispatch(getUserAccess(user.user_id));
    }
    window.navigator.geolocation.getCurrentPosition((pos) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (event) => {
    setIsDirty(true);
    setDeliveryDate(event.target.value);
    if (userAccess[0]?.org_id) {
      dispatch(
        retrieveOrders(
          userAccess[0]?.org_id,
          0,
          !event.target.value ? "" : formatDate(new Date(event.target.value))
        )
      );
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.actions}>
        <Typography variant="h6" fontWeight={700}>
          Deliveries
        </Typography>
        <Box className={classes.btnBox}>
          <TextField
            className={classes.textField}
            required={true}
            label="Date"
            type="date"
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              className: classes.inputFeild,
            }}
            name="order.delivery_date"
            value={deliveryDate}
            onChange={handleDateChange}
          />
          <Button
            fullWidth
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => {
              nav("/deliveries/create");
            }}
            className={classes.btnStyle}
          >
            Create
          </Button>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Grid container spacing={0} className={classes.paper}>
            <Grid item xs={12} md={4} className={classes.cardPaper}>
              <StatCard
                label="Total Deliveries"
                stat={stats.deliveriesIncomplete}
              />
              <Divider
                orientation="vertical"
                flexItem
                className={classes.dividerPosition}
              />
            </Grid>

            <Grid item xs={12} md={4} className={classes.cardPaper}>
              <StatCard
                label="Total Incomplete"
                stat={stats.deliveriesAllTime}
              />
              <Divider
                orientation="vertical"
                flexItem
                className={classes.dividerPosition}
              />
            </Grid>

            <Grid item xs={12} md={4} className={classes.cardPaper}>
              <StatCard
                label="Customers Served"
                stat={stats.patientsServiced}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {orders != null ? (
        <div className={classes.ordersWrapper}>
          <br />
          <OrderTable
            getMoreOrders={() => {
              dispatch(
                retrieveOrders(
                  userAccess[0]?.org_id,
                  Object.keys(orders.ordersList).length,
                  isDirty ? deliveryDate : ""
                )
              );
            }}
            orders={orders}
          />
        </div>
      ) : (
        <div className={classes.spinner}>
          <Loader
            type="Bars"
            color={weelTheme.palette.secondary.main}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
};

export default OrdersContainer;
