import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { toast } from "react-hot-toast";
import AddIcon from "@material-ui/icons/Delete";
import CloseIcon from "@mui/icons-material/Close";

import Typography from "@material-ui/core/Typography";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Stack } from "@mui/material";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import { useStyles } from "./style";
import EditIcon from "@mui/icons-material/Edit";

import "./style.css";
import DriverAssignment from "./components/driverAssignment";
import MenuPopupState from "../components/menuPopupState";
import StatusChip from "../components/statusChip";
import {
  fetchTagsByOrders,
  getUploadedImages,
  removeOrderTag,
  updateOrder,
} from "../services/api/requests";
import { useAppSelector } from "../hooks";
import { transformString } from "../services/helper";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import { AddressSearch } from "../components/addressSearch";
import { useFormik, FormikProps } from "formik";
import { UpdateOrder } from "../ordersCreate/types";
import UpdateDeliveryItems from "../ordersCreate/updateDeliveryItems";
import { OrderInfo } from "../store/orders/types";
import { useDispatch } from "react-redux";
import { fetchOrderDetails } from "../store/orders/middleware";
import AvailableDeliveryTags from "../ordersCreate/availableTags";
import remove from "../assets/remove.png";
import { Tag } from "../store/organizations/types";

type GeoLocationType = {
  lat: number;
  long: number;
};
const deliveryItems = [
  { id: 1, name: "Regular" },
  { id: 2, name: "Secure" },
  { id: 3, name: "Fridge" },
  { id: 4, name: "Boxes" },
  { id: 5, name: "Envelope" },
];
interface UploadedImage {
  asset_id: number;
  secure_url: string;
}
const OrderDetails: React.FC<{}> = () => {
  const location = useLocation();
  const { order, orderId } = location.state || {};

  const styles = useStyles();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const org = useAppSelector((state) => state.organization);
  const currentOrder = useAppSelector<any>(
    (state) => state.orders.orderDetails
  );

  const [tags, setTags] = useState<Tag[]>([]);
  const [availableTags, setAvailableTags] = useState<Tag[]>(org?.org_tags);
  const { deliveryId } = useParams();
  const [editingState, setEditingState] = useState({
    unitAddress: false, // For editing unit and address
    amount: false, // For editing amount
    deliveryDate: false, // For editing delivery date
    deliveryPreference: false, // For editing delivery preference
    notes: false,
    sub_item_regular: false,
    sub_item_secure: false,
    sub_item_fridge: false,
    sub_item_boxes: false,
    sub_item_envelope: false,
    // Add more fields here as needed
  });
  const [isNotesEdited, setIsNotesEdited] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // const availableTags: string[] = ["Urgent", "Coldchain", "Narcotics"];
  // Store the initial value of the notes for comparison
  const [geoLocation, setGeoLocation] = useState<GeoLocationType>({
    lat: 0,
    long: 0,
  });
  const initialNotesValue = currentOrder.notes;
  const goBack = () => nav("/deliveries", { replace: true });
  useEffect(() => {
    const tagsBox = document.getElementById("tagsBox");
    if (tagsBox) {
      tagsBox.style.overflowY = "auto";

      (tagsBox.style as any).scrollbarWidth = "none"; // For Firefox
      (tagsBox.style as any).msOverflowStyle = "none"; // For Internet Explorer 10+
      (tagsBox.style as any).WebkitScrollbar = "none"; // For Safari and Chrome
    }
    getTagsByOrder();
    getOrderImages();
    getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTagsByOrder = async () => {
    try {
      const response = await fetchTagsByOrders(org.org_id, deliveryId);
      setTags(response.data.tags);

      setAvailableTags(
        org?.org_tags.filter((tag) => !response.data.tags.includes(tag.name))
      );
      setTags(
        org?.org_tags.filter((tag) => response.data.tags.includes(tag.name))
      );
    } catch (error) {}
  };

  const getOrderDetails = async () => {
    try {
      dispatch(fetchOrderDetails(org.org_id, deliveryId));
    } catch (error) {}
  };
  const getOrderImages = async () => {
    try {
      const response = await getUploadedImages(org.org_id, deliveryId);
      setUploadedImages(response?.data?.assets);
    } catch (error) {}
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formik: FormikProps<UpdateOrder> = useFormik<UpdateOrder>({
    initialValues: {
      delivery_date: currentOrder.delivery_date,
      delivery_preference: "no-preference",
      delivery_preference_start: null,
      delivery_preference_end: null,
      amount: currentOrder?.amount,
      amount_collected: currentOrder?.amount_collected,
      notes: currentOrder?.notes,

      to_unit: currentOrder?.to_unit,
      to_address: currentOrder?.to_address,
      to_latitude: currentOrder?.to_latitude,
      to_longitude: currentOrder?.to_longitude,
      sub_item_regular: currentOrder?.sub_item_regular,
      sub_item_secure: currentOrder?.sub_item_secure,
      sub_item_fridge: currentOrder?.sub_item_fridge,
      sub_item_boxes: currentOrder?.sub_item_boxes,
      sub_item_envelope: currentOrder?.sub_item_envelope,
    },
    validateOnChange: true, // Validate on every change
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateOrder(currentOrder.org_id, deliveryId, values)
        .then((res) => {
          toast.success(`Order Updated Successfully`);
          formik.resetForm();
          getOrderDetails();
          setIsItemsEdited(false);
        })
        .catch((err) => {
          toast.error(
            "Woops! something went wrong when trying to create a new organization"
          );
          console.error(err);
        });
      handleReset();
    },
  });

  const handleReset = () => {
    // Reset form values to initial ones
    // formik.setValues(initialValues)
    setEditingState({
      unitAddress: false, // For editing unit and address
      amount: false, // For editing amount
      deliveryDate: false, // For editing delivery date
      deliveryPreference: false, // For editing delivery preference
      notes: false,
      sub_item_regular: false,
      sub_item_secure: false,
      sub_item_fridge: false,
      sub_item_boxes: false,
      sub_item_envelope: false,
    });
  };

  useEffect(() => {
    // Enable the button when notes value changes from its initial value
    if (formik.values.notes !== initialNotesValue) {
      setIsNotesEdited(true);
    } else {
      setIsNotesEdited(false);
    }
  }, [formik.values.notes, initialNotesValue]);

  const [isItemsEdited, setIsItemsEdited] = React.useState(false);
  const initialValues = React.useMemo(
    () => ({
      sub_item_regular: formik.values.sub_item_regular,
      sub_item_secure: formik.values.sub_item_secure,
      sub_item_fridge: formik.values.sub_item_fridge,
      sub_item_boxes: formik.values.sub_item_boxes,
      sub_item_envelope: formik.values.sub_item_envelope,
    }),
    []
  );

  React.useEffect(() => {
    const hasChanges = Object.keys(initialValues).some(
      (key) => formik.values[key] !== initialValues[key]
    );
    setIsItemsEdited(hasChanges);
  }, [formik.values, initialValues]);

  const handleAddressSelect = (address: any) => {
    formik.setFieldValue("to_address", address.address);
    formik.setFieldValue("to_latitude", address.lat);
    formik.setFieldValue("to_longitude", address.long);
  };
  const handleEditClick = (field) => {
    setEditingState({ ...editingState, [field]: true });
  };

  const removeTag = async (tagId) => {
    try {
      const response = await removeOrderTag(
        currentOrder.org_id,
        deliveryId,
        tagId
      );
    } catch (error) {}
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container xs={12} item spacing={4} className={styles.root}>
        <Grid item sm={10} style={{ padding: 0, paddingLeft: 16 }}>
          <Stack
            mb={0}
            display="flex"
            flexDirection="row"
            spacing={0}
            padding={0}
            marginTop={2}
            alignItems="center"
          >
            <NavigateBeforeIcon
              onClick={() => goBack()}
              className={styles.icon}
            />
            <Typography variant="h6" component="div" className={styles.heading}>
              {currentOrder.name}
            </Typography>
          </Stack>
        </Grid>

        <Grid
          id="tagsBox"
          item
          sm={12}
          md={8}
          lg={7}
          className={styles.tagsBox}
        >
          <Box className={styles.tagSection}>
            <StatusChip status={currentOrder.status} />
            <Chip
              className={styles.statusChip}
              size="small"
              label={currentOrder.type}
            />
            {tags?.map((tag, idx) => (
              <Chip
                onDelete={() => {
                  removeTag(tag.id);
                }}
                key={idx}
                style={{
                  background: "#" + tag.color,
                  width: "auto",
                  // margin: 5
                }}
                className={styles.chip}
                size="small"
                label={tag.name}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        </Grid>

        <Grid item sm={12} md={8} lg={7}>
          <Paper className={styles.card}>
            <Box className={styles.header}>
              <Typography variant="subtitle1" className={styles.subtitleClass}>
                Customer Details
              </Typography>
              <MenuPopupState onDeleteClick={() => {}} />
            </Box>
            <Typography variant="body1" className={styles.textStyle}>
              {order?.customer || "---"}
            </Typography>
            <div className={styles.textContainer}>
              {editingState.unitAddress ? (
                <Grid container item xs={10} sm={10} md={10} spacing={1}>
                  <Grid item xs={3} sm={3} md={3}>
                    <div style={{ width: 125, flexShrink: 0, paddingRight: 8 }}>
                      <TextField
                        id="Unit"
                        className={styles.textField}
                        name={`to_unit`}
                        type="text"
                        size="small"
                        error={false}
                        label="Unit"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.to_unit}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={styles.addressSearchField}>
                      <AddressSearch
                        onAddressSelect={handleAddressSelect}
                        currentAddress={formik.values.to_address}
                        lat={formik.values.to_latitude}
                        long={formik.values.to_longitude}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body1" className={styles.textStyle}>
                  {currentOrder.unit} {currentOrder.to_address}
                </Typography>
              )}

              {/* Edit icon, visible on hover */}
              {!editingState.unitAddress && (
                <IconButton
                  size="small"
                  className={styles.editIcon}
                  onClick={() => handleEditClick("unitAddress")} // Enable editing on icon click
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>
            {currentOrder?.phone_number && (
              <Box className={styles.IconBox}>
                <PhoneIphoneIcon />
                <Typography variant="body1" className={styles.textStyle}>
                  {currentOrder.phone_number}
                </Typography>
              </Box>
            )}
            {currentOrder?.email && (
              <Box className={styles.IconBox}>
                <AlternateEmailIcon />
                <Typography variant="body1" className={styles.textStyle}>
                  {currentOrder?.email}
                </Typography>
              </Box>
            )}
            <Typography
              variant="subtitle1"
              className={styles.subtitleClass}
              style={{ marginTop: 15 }}
            >
              Order Details
            </Typography>

            <div className={styles.textContainer}>
              {editingState.deliveryDate ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="date"
                      className={styles.textField}
                      required
                      label="Date"
                      type="date"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="delivery_date"
                      value={formik.values.delivery_date}
                      onChange={formik.handleChange}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0], // Sets minimum date to today's date
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="preference"
                        shrink
                        style={{ padding: 3, background: "#FFFF" }}
                      >
                        Preference
                      </InputLabel>
                      <Select
                        labelId="preference"
                        required
                        style={{ height: "2.375rem", borderRadius: 5 }}
                        value={formik.values.delivery_preference}
                        defaultValue="no-preference"
                        name="order.delivery_preference"
                        onChange={formik.handleChange}
                      >
                        <MenuItem value="no-preference">None</MenuItem>
                        <MenuItem value="before">Before</MenuItem>
                        <MenuItem value="between">Between</MenuItem>
                        <MenuItem value="after">After</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {formik.values.delivery_preference !== "no-preference" && (
                    <Grid item xs={3}>
                      <TextField
                        className={styles.textField}
                        label="Start"
                        type="time"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        name="delivery_preference_start"
                        value={formik.values.delivery_preference_start}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  )}

                  {formik.values.delivery_preference === "between" && (
                    <Grid item xs={3}>
                      <TextField
                        className={styles.textField}
                        required
                        label="End"
                        type="time"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="delivery_preference_end"
                        value={formik.values.delivery_preference_end}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography variant="body1" className={styles.textStyle}>
                  Delivery Date:
                  {moment(currentOrder.delivery_date)
                    .utc()
                    .format("dddd, MMMM Do YYYY")}
                  , {transformString(currentOrder.delivery_preference)}{" "}
                  {currentOrder.delivery_preference !== "no_preference" &&
                    moment(currentOrder.preference_start)
                      .utc()
                      .format(" h:mm:ss a")}{" "}
                  {currentOrder.delivery_preference === "between" &&
                    `and ${moment(currentOrder.preference_end)
                      .utc()
                      .format(" h:mm:ss a")}`}
                </Typography>
              )}

              {/* Edit Icon */}
              {!editingState.deliveryDate ? (
                <IconButton
                  size="small"
                  className={styles.editIcon}
                  onClick={() =>
                    setEditingState({ ...editingState, deliveryDate: true })
                  }
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              ) : (
                <></>
              )}
            </div>

            <div className={styles.textContainer}>
              {editingState.amount ? (
                <Grid item xs={4}>
                  <TextField
                    id="Amount"
                    required={true}
                    className={styles.textField}
                    label="Amount"
                    name="amount"
                    size="small"
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                  />
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  className={styles.textStyle}
                  onClick={() => editingState.amount} // Enable editing on click
                >
                  Amount: ${currentOrder.amount} CAD
                </Typography>
              )}

              {/* Edit icon, visible on hover */}
              {!editingState.amount && (
                <IconButton
                  size="small"
                  className={styles.editIcon}
                  onClick={() => handleEditClick("amount")} // Enable editing on icon click
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>

            {currentOrder.amount_collected > 0 ? (
              <Typography variant="body1" className={styles.textStyle}>
                Amount Collected : ${currentOrder.amount_collected} CAD
              </Typography>
            ) : (
              <Typography variant="body1" className={styles.textStyle}>
                Amount Collected : Not collected yet
              </Typography>
            )}
            {currentOrder?.payment_method ? (
              <Typography variant="body1" className={styles.textStyle}>
                Payment method: {currentOrder?.payment_method}
              </Typography>
            ) : (
              <Typography variant="body1" className={styles.textStyle}>
                Payment method: N/A
              </Typography>
            )}
            <Typography
              variant="subtitle1"
              className={styles.subtitleClass}
              style={{ marginTop: 15 }}
            >
              Notes
            </Typography>

            <Grid
              className={`${styles.gridContainer} ${styles.notesMargin}`}
              container
            >
              <Grid item xs={12}>
                <TextareaAutosize
                  id="notes"
                  className={styles.textArea}
                  name="notes"
                  placeholder="Order notes"
                  minRows={3}
                  onChange={formik.handleChange}
                  value={formik.values.notes}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" className={styles.subtitleClass}>
              Uploaded Assets
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: 20,
              }}
            >
              {uploadedImages?.length > 0 ? (
                uploadedImages?.map((link, index) => (
                  <a
                    key={index}
                    href={link.secure_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "black" }}
                  >
                    {link?.secure_url}
                  </a>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  className={styles.subtitleClass}
                >
                  No Assets available
                </Typography>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid style={{ marginTop: 15 }}>
          <AvailableDeliveryTags
            tags={availableTags}
            formik={formik}
            orgId={org.org_id}
            orderId={orderId}
            refreshData={() => {
              getOrderDetails();
              getTagsByOrder();
            }}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={7}>
          <UpdateDeliveryItems subItems={deliveryItems} formik={formik} />
        </Grid>
        <Grid item sm={12} md={8} lg={7}>
          <Paper className={styles.card}>
            <Grid item container sm={11}>
              <Box className={styles.subHeader}>
                <Typography variant="body1" className={styles.subtitleClass}>
                  Driver Assignment
                </Typography>
              </Box>
              <Typography variant="body1" className={styles.textStyle}>
                The driver assigned to the order. If no one is assigned, you can
                assign someone here.
              </Typography>
              <Grid item sm={8} md={8} lg={8} style={{ marginTop: 16 }}>
                <DriverAssignment
                  orderId={deliveryId}
                  driverId={currentOrder?.driver_id}
                  status={currentOrder.status}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item sm={12} md={8} lg={7} spacing={1}>
          {(isNotesEdited ||
            isItemsEdited ||
            Object.values(editingState).some((value) => value)) && (
            <Box display="flex" justifyContent="end" width="100%">
              <Button
                id="cancel"
                variant="contained"
                disableFocusRipple={true}
                disableRipple={true}
                className={styles.createOrder}
                type="button"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  handleReset();
                }}
              >
                Cancel
              </Button>
              <Button
                id="create"
                variant="contained"
                disableFocusRipple={true}
                disableRipple={true}
                className={styles.createOrder}
                type="submit"
                // Add some space between buttons
              >
                Save
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default OrderDetails;
