import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useStyles as styles } from "./styles";
import { getUserAccess } from "../store/authentication/access/middleware";
import { getOrganization } from "../store/organizations/middleware";
import Loader from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import weelTheme from "../components/material-ui/theme";
import { RootState } from "../store/types";
import { fetchServiceAgreement, fetchServiceAgreementZones, getOrganizationStats, updateDeliveryType } from "../services/api/requests";
import { Typography } from "@mui/material";

import MapComponent from "./zoneMap";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ServiceAgreementTable from "./serviceAgreementTable";
import AccessibleTabs from "./serviceAgreementTable/tabs";
import AddZoneModal from "./addZoneModal";
import { TextField } from "@material-ui/core";
import { Zones } from "../store/orders/types";
import RateInput from "./switchZoneModal/ratecomponent";

const ServiceAgreement: React.FC<{}> = () => {
  const nav = useNavigate();
  const classes = styles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.account);
  const userAccess = useSelector((state: RootState) => state.user.access);
  const orders = useSelector((state: RootState) => state.orders);
  const [serviceAgreementData, setServiceAgreementData] = useState<Zones>()
  const [zones, setZones] = useState<Zones[]>([])
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const pharmacy = location.state?.pharmacy;

  // console.log("agreement oharmacy ", pharmacy)
  const [stats, setStats] = useState<any>({
    deliveriesToday: 0,
    deliveriesAllTime: 0,
    patientsServiced: 0,
    deliveryChart: {
      categories: [],
      data: [],
    },
    patientChart: {
      categories: [],
      data: [],
    },
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getAgreementZones()
  };
  useEffect(() => {
    if (!userAccess || userAccess.length === 0) {
      dispatch(getUserAccess(user.user_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userAccess &&
      userAccess[0]?.org_id &&
      userAccess.length > 0 &&
      userAccess[0]?.org_id
    ) {
      dispatch(getOrganization(userAccess[0]?.org_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess]);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((pos) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgreement = async () => {

    const agreementResponse = await fetchServiceAgreement(userAccess[0]?.org_id, id)
    // console.log("respsoe", agreementResponse.data)
    setServiceAgreementData(agreementResponse.data)
  }

  const getAgreementZones = async () => {

    const agreementResponse = await fetchServiceAgreementZones(userAccess[0]?.org_id, id)
    // console.log("zones response", agreementResponse?.data?.zones)
    setZones(agreementResponse?.data?.zones ? agreementResponse?.data?.zones : [])
  }

  const switchType = async (payload) => {

    const agreementResponse = await updateDeliveryType(userAccess[0]?.org_id, id, payload)
    getAgreement()
  }

  useEffect(() => {
    // getOrganizationStats(userAccess[0]?.org_id)
    //   .then((resp) => {
    //     setStats({
    //       ...stats,
    //       deliveriesToday: resp.data.deliveriesToday,
    //       deliveriesAllTime: resp.data.deliveriesAllTime,
    //       patientsServiced: resp.data.patientsServiced,
    //     });
    //   })
    //   .catch((err) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAgreement()
    getAgreementZones()
  }, []);

  const goBack = () => nav(`/pharmacy/${id}`, { replace: true });


  useEffect(() => {

    const payload = {
      type: value == 0 ? 'per_delivery' : 'zonal',
      rate: serviceAgreementData?.rate
    }
    switchType(payload)
  }, [value])

  return (
    <div className={classes.root}>
      <Box className={classes.actions}>
        <NavigateBeforeIcon onClick={() => goBack()} className={classes.icon} />
        <Typography variant="h6" fontWeight={700} className={classes.heading}>
          {pharmacy?.Name} - Service Agreement
        </Typography>
      </Box>
      <Box className={classes.header}>
        <AccessibleTabs setValue={setValue} value={value} onChange={() => {


        }} />
        {value === 1 && (
          <Button
            fullWidth
            disableFocusRipple={true}
            disableRipple={true}
            onClick={handleOpen}
            className={classes.btnStyle}
          >
            Add Zone
          </Button>
        )}
      </Box>
      {value === 0 ? (
        <Box className={classes.zoneBody}>
          <Typography variant="body1">
            Each delivery that is created by this pharmacy has a flat rate.
            <br /> The rate can be viewed and adjusted as the agreement between
            you
            <br /> and the pharmacy dictates.
          </Typography>
          {/* <TextField
            required={true}
            className={classes.textField}
            name={`rate`}
            type="number"
            size="small"
            label="Rate"
            variant="outlined"
          // value={serviceAgreementData?.rate}
          /> */}

          {serviceAgreementData != null &&
            <RateInput serviceRate={serviceAgreementData?.rate} onUpdateRate={(serviceRate) => {

              const payload = {
                type: 'per_delivery',
                rate: parseFloat(serviceRate)
              }
              switchType(payload)

            }} />}

        </Box>
      ) : (
        <Box>
          <Typography variant="h6" fontWeight={700}>
            Zones
          </Typography>
          <AddZoneModal
            handleClose={handleClose}
            handleOpen={handleOpen}
            open={open}
            orgId={userAccess[0]?.org_id}
            pharmacyId={id}
          />

          {zones != null ? (
            <div className={classes.ordersWrapper}>
              <br />
              <ServiceAgreementTable
                getMoreOrders={() => { }}

                orders={zones}
                orgId={userAccess[0]?.org_id}
                pharmacyId={id}
                onDelete={() => { getAgreementZones() }}

              />
            </div>
          ) : (
            <div className={classes.spinner}>
              <Loader
                type="Bars"
                color={weelTheme.palette.secondary.main}
                height={100}
                width={100}
              />
            </div>
          )}
          <Box style={{ height: 362 }}>
            <MapComponent />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ServiceAgreement;
