import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStyles } from "./styles";
import { Draggable } from "react-beautiful-dnd";
import StatusChip from "../../../../../components/statusChip";



export interface Item {
  place: number;
  id: number;
  customer: string;
  unit: string;
  address: string;
  lat: number;
  long: number;
  tags?: null;
  preference: string;
  status: string
}

const DeliveriesListing = ({ index, item }: { index: number, item: Item }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  console.log("itemssss", item)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Draggable draggableId={`item-${index}`} index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          md={12}
          className={classes.mainContainer}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,

            marginLeft: snapshot.isDragging ? -200 : 0,
            backgroundColor: snapshot.isDragging ? "white" : "transparent",
            border: snapshot.isDragging ? "2px solid #1976d2" : "none",
            boxShadow: snapshot.isDragging
              ? "0 4px 10px rgba(0, 0, 0, 0.15)"
              : "none",
            borderRadius: "4px",
          }}
        >
          <Box p={1} border={1} borderColor="grey.300" borderRadius={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography variant="body1" gutterBottom>
                {index + 1} - RX-{item.id} - {item.customer}
              </Typography>
              <IconButton
                aria-label="more"
                style={{ padding: 8 }}
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
                <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
                <MenuItem onClick={handleMenuClose}>Option 3</MenuItem>
              </Menu>
            </Box>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {item.address}
            </Typography>
            <Box display="flex" mt={2} gridColumnGap={5}>
              <StatusChip status={item.status} />
              {/* <Button variant="outlined" style={{ marginLeft: 4 }}>
                Before 9:00 AM
              </Button> */}
            </Box>
          </Box>
        </Grid>
      )}
    </Draggable>
  );
};

export default DeliveriesListing;
