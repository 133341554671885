import React from "react";
import {
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  Checkbox,
  Typography,
  Chip,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FormikProps } from "formik";
import { FormikCreateOrder, UpdateOrder } from "../types";
import { addOrderTags } from "../../services/api/requests";
import { number } from "yup";
import { prop } from "ramda";

type Tag = { id: number; name: string, color: string };

type AvailableDeliveryTagsProps = {

  tags: Array<Tag>;
  formik: FormikProps<UpdateOrder>;
  orgId: string,
  orderId: number
  refreshData: () => void
};

const AvailableDeliveryTags: React.FC<AvailableDeliveryTagsProps> = (props) => {
  const { formik, tags } = props;
  console.log("tags", tags)
  const classes = useStyles();

  // const addOrRemoveTags = (tag) => {
  //   const currentTags = formik.values;
  //   const tagId = tag.id;
  //   if (currentTags.includes(tagId)) {
  //     // Remove the tag ID from the array
  //     formik.setFieldValue(
  //       "order.tags",
  //       currentTags.filter((value) => value !== tagId)
  //     );
  //   } else {
  //     // Add the tag ID to the array
  //     formik.setFieldValue("order.tags", [...currentTags, tagId]);
  //   }
  // };
  const handleTagClick = async (tag) => {
    // Handle the click event here
    console.log('Tag clicked:', tag);
    const data = { tag_name: tag.name }
    await addOrderTags(props.orgId, props.orderId, data)
    props.refreshData()
    // Add your logic here, for example:
    // - Filter items by tag
    // - Navigate to tag details
    // - Show tag-related information
    // - etc.
  };
  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.body}>
          <Box className={classes.content}>
            <FormControl component="fieldset" variant="standard">
              <Typography
                variant="h6"
                component="div"
                className={classes.heading}
              >
                Tags Available
              </Typography>
              <FormGroup>
                {tags &&
                  tags.map((tag, idx) => (
                    <Chip
                      key={idx}
                      className={classes.chip}
                      size="small"
                      style={{
                        background: '#' + tag.color,
                        margin: 5
                      }}
                      label={tag.name}
                      onClick={() => handleTagClick(tag)}
                      // Material-UI core specific props
                      clickable={true}
                      variant="default"
                    />
                  ))}

              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default AvailableDeliveryTags;
