import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Stack, TextareaAutosize, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useStyles } from "./styles";
import { FormikCreateOrder } from "../types";
import StyledPhoneInput from "../../components/styledPhoneInput";
import { useAppSelector } from "../../hooks";
import { fetchPatientsByName } from "../../services/api/requests";

type CreateNewDeliveryProps = {
  formik: FormikProps<FormikCreateOrder>;
};

type Location = {
  id: number;
  preferred: boolean;
  unit: string;
  address: string;
  lat: number;
  long: number;
  distance: number;
};

type Patient = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: number;
  ext: number;
  notes: number;
  locations: Location[];
  date_created: string;
  date_updated: string;
};

const CreateNewDelivery: React.FC<CreateNewDeliveryProps> = (props) => {
  const { formik } = props;
  const org = useAppSelector((state) => state.organization);
  const [patients, setPatients] = useState([]);
  const classes = useStyles();
  const [showDropDown, setShowDropDown] = useState(true);
  const handleChangeRadio = (idx) => {
    const fullAddressState = formik.values.customer.fullAddress.map(
      (address) => {
        return {
          ...address,
          primaryAddress: "false",
        };
      }
    );
    fullAddressState[idx].primaryAddress = "true";

    formik.setFieldValue("customer.fullAddress", fullAddressState);
  };

  const searchPatientByName = async () => {
    try {
      if (!formik.values.customer.firstName) {
        return;
      }
      const response = await fetchPatientsByName(
        org.org_id,
        formik.values.customer.firstName
      );

      setPatients(response.data.patients);
    } catch (error) {
      setPatients([]);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchPatientByName();
    }, 300);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.customer.firstName]);
  const hanldeCustomerSelect = (patientInfo) => {
    setShowDropDown(false);
    const primaryAddressObject = patientInfo.locations.find(
      (address: any) => address.preferred === true
    );

    //order
    formik.setFieldValue("order.from_address", org.address);
    formik.setFieldValue("order.from_longitude", org.long);
    formik.setFieldValue("order.from_latitude", org.lat);
    formik.setFieldValue("order.from_unit", org.unit);
    formik.setFieldValue("order.to_longitude", primaryAddressObject.long);
    formik.setFieldValue("order.to_latitude", primaryAddressObject.lat);
    formik.setFieldValue("order.to_unit", primaryAddressObject.unit);
    formik.setFieldValue("order.to_address", primaryAddressObject.address);
    formik.setFieldValue(
      "order.estimated_distance",
      primaryAddressObject.distance || 0
    );

    //custumer
    formik.setFieldValue(
      "customer.firstName",
      patientInfo.firstName + " " + patientInfo.lastName
    );
    formik.setFieldValue("customer.lastName", patientInfo.lastName);
    formik.setFieldValue("customer.email", patientInfo.email);
    formik.setFieldValue("customer.phoneNumber", patientInfo.phoneNumber);
    formik.setFieldValue("customer.notes", patientInfo.notes);
    formik.setFieldValue("customer.extension", patientInfo.ext);
    formik.setFieldValue("customer.fullAddress", patientInfo.locations);
    formik.setFieldValue("customer.customer_id", patientInfo.id);
    setPatients([]);
  };

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box className={classes.header}>
          <Typography variant="h6" className={classes.title} fontWeight={700}>
            Customer Details
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Stack
            className={classes.stack}
            display="flex"
            flexDirection="column"
            spacing={2}
          >
            <Box>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={12} className={classes.searchBox}>
                  <Box className={classes.searchPosition}>
                    <Box>
                      <TextField
                        id="customer.firstName"
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Customer Name"
                        required={true}
                        name="customer.firstName"
                        fullWidth={true}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setShowDropDown(true);
                        }}
                        value={formik.values.customer.firstName}
                        autoComplete="off"
                      />
                      <SearchIcon className={classes.searchIcon} />
                    </Box>
                    {patients?.length > 0 && showDropDown ? (
                      <Box className={classes.optionsBox}>
                        {patients.map((item: Patient, idx: number) => (
                          <Box
                            key={`patient-${idx}`}
                            className={classes.option}
                            onClick={() => hanldeCustomerSelect(item)}
                          >
                            <Typography variant="body1" fontWeight={600}>
                              {item.firstName} {item.lastName} <br />
                              {
                                item.locations.find(
                                  (loc) => loc.preferred === true
                                )?.address
                              }
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={5}>
                  <StyledPhoneInput
                    formik={formik}
                    fieldName="customer.phoneNumber"
                    style={{ pointerEvents: "none" }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="ext"
                    className={classes.textField}
                    name="customer.extension"
                    type="number"
                    size="small"
                    fullWidth
                    label="Primary Ext"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.customer.extension}
                    style={{ pointerEvents: "none" }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="email"
                    className={classes.textField}
                    name="customer.email"
                    size="small"
                    onChange={formik.handleChange}
                    value={formik.values.customer.email}
                    variant="outlined"
                    label="Email"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              {formik.values.customer.fullAddress.map((item: any, idx) => (
                <>
                  <div
                    key={`delivery-${idx}`}
                    style={{ marginBottom: 8 }}
                    className={classes.addressContainer}
                  >
                    <Radio
                      className={classes.unitBox}
                      name={`customer.fullAddress[${idx}].primaryAddress`}
                      checked={item.preferred === true}
                      onChange={() => {
                        handleChangeRadio(idx);
                      }}
                      value={item.preferred}
                    />
                    <div style={{ width: 125, flexShrink: 0, paddingRight: 8 }}>
                      <TextField
                        id="Unit"
                        className={classes.textField}
                        name={`customer.fullAddress[${idx}].unit`}
                        type="text"
                        size="small"
                        error={false}
                        label="Unit"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={item.unit}
                        style={{ pointerEvents: "none" }}
                      />
                    </div>
                    <div style={{ flexGrow: 1, paddingLeft: 8 }}>
                      <TextField
                        id={`customer.fullAddress[${idx}].address`}
                        required={true}
                        className={classes.textField}
                        name={`customer.fullAddress[${idx}].address`}
                        type="text"
                        size="small"
                        label="Address"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={item.address}
                        style={{ pointerEvents: "none" }}
                        fullWidth
                      />
                    </div>
                  </div>
                </>
              ))}
              <Grid
                className={`${classes.gridContainer} ${classes.notesMargin}`}
                container
              >
                <Grid item xs={12}>
                  <TextareaAutosize
                    id="customer.notes"
                    className={classes.textArea}
                    name="customer.notes"
                    placeholder="Customer notes"
                    minRows={3}
                    onChange={formik.handleChange}
                    value={formik.values.customer.notes}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Box mb={1} className={classes.subtitleContainer}>
                <Typography
                  variant="h6"
                  className={classes.title}
                  fontWeight={700}
                >
                  Delivery Details
                </Typography>
              </Box>
              <RadioGroup
                className={classes.radioGroup}
                row
                name="order.type"
                value={formik.values.order.type}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  classes={{ label: classes.label }}
                  style={{ marginRight: "23px" }}
                  value="pickup"
                  control={<Radio />}
                  label="Pickup"
                />
                <FormControlLabel
                  classes={{ label: classes.label }}
                  value="delivery"
                  control={<Radio />}
                  label="Delivery"
                />
              </RadioGroup>
              <Grid className={classes.gridContainer} container spacing={2}>
                {/* <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      shrink
                      style={{ padding: 3, background: "#FFFF" }}
                    >
                      Bulk Box
                    </InputLabel>
                    <Select
                      required={true}
                      style={{ height: "2.375rem", borderRadius: 5 }}
                      classes={{ select: classes.select }}
                      value={formik.values.order.delivery_preference}
                      defaultValue="no-preference"
                      name="order.delivery_preference"
                      onChange={formik.handleChange}
                    >
                      <MenuItem key={1} value="no-preference">
                        None
                      </MenuItem>
                      <MenuItem key={2} value="before">
                        Before
                      </MenuItem>
                      <MenuItem key={3} value="between">
                        Between
                      </MenuItem>
                      <MenuItem key={4} value="after">
                        After
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    className={classes.textField}
                    required={true}
                    label="Date"
                    type="date"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    name="order.delivery_date"
                    value={formik.values.order.delivery_date}
                    onChange={formik.handleChange}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0], // Sets minimum date to today's date
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      id="preference"
                      aria-label="preference"
                      data-testid="preference"
                      shrink
                      style={{ padding: 3, background: "#FFFF" }}
                    >
                      Preference
                    </InputLabel>
                    <Select
                      labelId="preference"
                      required={true}
                      style={{ height: "2.375rem", borderRadius: 5 }}
                      classes={{ select: classes.select }}
                      value={formik.values.order.delivery_preference}
                      defaultValue="no-preference"
                      name="order.delivery_preference"
                      onChange={formik.handleChange}
                    >
                      <MenuItem key={1} value="no-preference">
                        None
                      </MenuItem>
                      <MenuItem key={2} value="before">
                        Before
                      </MenuItem>
                      <MenuItem key={3} value="between">
                        Between
                      </MenuItem>
                      <MenuItem key={4} value="after">
                        After
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values.order.delivery_preference !==
                  "no-preference" && (
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      label="Start"
                      type="time"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="order.delivery_preference_start"
                      value={formik.values.order.delivery_preference_start}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
                {formik.values.order.delivery_preference === "between" && (
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      required={true}
                      label="End"
                      type="time"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="order.delivery_preference_end"
                      value={formik.values.order.delivery_preference_end}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      id="recurrence"
                      aria-label="recurrence"
                      shrink
                      style={{ padding: 3, background: "#FFFF" }}
                    >
                      Recurrence
                    </InputLabel>
                    <Select
                      labelId="recurrence"
                      data-testid="recurrence-select"
                      required={true}
                      style={{ height: "40px", borderRadius: "10px" }}
                      classes={{ select: classes.select }}
                      fullWidth
                      variant="outlined"
                      value={formik.values.order.recurrence}
                      defaultValue={"no-recurrence"}
                      name="order.recurrence"
                      onChange={formik.handleChange}
                    >
                      <MenuItem key={1} value={"once"}>
                        Once
                      </MenuItem>
                      <MenuItem key={2} value={"daily"}>
                        Daily
                      </MenuItem>
                      <MenuItem key={3} value={"weekly"}>
                        Weekly
                      </MenuItem>
                      <MenuItem key={3} value={"weekly"}>
                        Bi-weekly
                      </MenuItem>
                      <MenuItem key={5} value={"monthly"}>
                        Monthly
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="Amount"
                    required={true}
                    className={classes.textField}
                    label="Amount"
                    name="order.amount"
                    size="small"
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={formik.values.order.amount}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="Submittedby"
                    className={classes.textField}
                    label="Submitted by"
                    name="order.submitted_by"
                    size="small"
                    type="text"
                    required={true}
                    fullWidth
                    variant="outlined"
                    value={formik.values.order.submitted_by}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.gridContainer} container>
                <Grid item xs={12}>
                  <TextareaAutosize
                    className={classes.textArea}
                    name="order.notes"
                    placeholder="Order notes"
                    minRows={3}
                    onChange={formik.handleChange}
                    value={formik.values.order.notes}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="end">
            <Button
              id="create"
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              className={classes.createOrder}
              type="submit"
            >
              Create
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default CreateNewDelivery;
